import { initClient } from "@ts-rest/core";
import { HttpSchema } from "@spaceblanc/http-schema";
import { PinataClient } from "../ancillary/pinata-client.js";

const ENV = {
  EDITOR_ENDPOINT: "https://editor.spaceblanc.xyz",
  PINATA_JWT: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJhOGQyZWJmOC0zNmFmLTQ1YTgtYWRlNy1jMTM1MWVjMzY3NWEiLCJlbWFpbCI6InNlcmdleUB1a3N0di5tZSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJiYjc2ZmMxM2M3NTVkZDJlYWZiMyIsInNjb3BlZEtleVNlY3JldCI6ImE5YTI5ZWYxOWM4ZjBmYTQwMjA4NDQ1NTllM2EzOTA5MmJkOWNmOTU2MmE0M2Q2MDk3YjM2NGU0ZjVhM2ZhOGUiLCJpYXQiOjE2OTM0MjEyMzl9.ToLdsFbq_2qRsmHKyYW-0x1wIBCwpenKxvNP-tbqS8k",
  PINATA_GATEWAY: "https://azure-urgent-skink-694.mypinata.cloud/",
};

export const EDITOR_ENDPOINT = process.env.EDITOR_ENDPOINT || ENV.EDITOR_ENDPOINT;
export const PINATA_JWT = process.env.PINATA_JWT || ENV.PINATA_JWT;
export const PINATA_GATEWAY = new URL(process.env.PINATA_GATEWAY || ENV.PINATA_GATEWAY);

export const CLIENT = initClient(HttpSchema, {
  baseUrl: EDITOR_ENDPOINT.replace(/(\/)+$/, ""), // No trailing slash
  baseHeaders: {},
});

export const PINATA_CLIENT = new PinataClient(PINATA_JWT, PINATA_GATEWAY);
